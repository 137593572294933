<template>
  <div
    class="internet-connection-snackbars"
  >
    <div class="internet-connection-overlay"></div>
    <v-snackbar
      bottom
      left
      :timeout="3000"
      transition="slide-y-reverse-transition"
      v-model="isOnlineModel"
    >
      <v-icon
        color="success success-lighten-1"
        class="mr-3"
      >
        mdi-wifi
      </v-icon>
      {{ $t('snackbars.internet-connection.restored-your-internet-connection') }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          icon
          style="text-transform: none;"
          v-bind="attrs"
          @click="isOnlineModel = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      bottom
      left
      :timeout="null"
      transition="slide-y-reverse-transition"
      v-model="isOfflineModel"
    >
      <v-icon
        color="#757575"
        class="mr-3"
      >
        mdi-wifi-off
      </v-icon>
      {{ $t('snackbars.internet-connection.offline-your-internet-connection') }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          icon
          style="text-transform: none;"
          v-bind="attrs"
          @click="isOfflineModel = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      bottom
      :timeout="3000"
      left
      transition="slide-y-reverse-transition"
      v-model="isOfflineActionModel"
    >
      <v-icon
        color="#757575"
        class="mr-3"
      >
        mdi-wifi-off
      </v-icon>
      {{ $t('snackbars.internet-connection.check-your-internet-connection') }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          icon
          style="text-transform: none;"
          v-bind="attrs"
          @click="onClick"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>

export default {
  name:'InternetConnectionSnackbar',
  data: () => ({
    isOnlineModel: false,
    isOfflineModel: false
  }),
  computed: {
    isOfflineActionModel: {
      get() {
        return this.$store.getters['isOfflineActionModel']
      },
      set(val) {
        this.$store.commit('setIsOfflineActionModel', val)
      }
    },
    isOffline() {
      return this.$store.getters.isOffline
    },
  },
  methods: {
    onClick() {
      this.isOfflineActionModel = false
    }
  },
  watch: {
    isOffline(to) {
      console.log("🚀 ~ file: internetConnectionSnackbar.vue ~ line 121 ~ isOffline ~ to", to)
      this.$store.commit('setIsOfflineActionModel', false)
      if (to) {
        this.isOnlineModel = false
        this.isOfflineModel = true
      } else {
        this.isOfflineModel= false
        this.isOnlineModel = true
      }
    }
  }
}
</script>
<style lang="scss">
.v-snack__content {
  display: flex;
  align-items: center;
}
</style>